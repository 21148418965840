<formly-form
  [form]="formlyForm"
  [fields]="formlyFields"
  [model]="formlyModel"
  [options]="formlyOptions"
  >
  <!-- Template das für jede Gruppe gerendert wird -->
  <ng-template formlyTemplate let-field>
    @if (field; as group) {
      <div
        class="full-width"
        cdkDropList
        (cdkDropListDropped)="drop($event, group)"
        [cdkDropListData]="group"
        >
        <!-- Gruppen Header für Custom Types die fieldGroups enthalten die nicht 'normal' gerendert werden (e.g. repeat und stepper) -->
        @if (group.parent && group.parent?.type !== formlyGroupType) {
          <div
            class="custom-gruppe-header"
            >
            <div class="custom-gruppe-title">
              <mat-card-title class="card-title">
                {{
                'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Bearbeiten.CustomFormlyTypeTitel'
                | translate : { type: group.parent.type }
                }}
              </mat-card-title>
              <mat-card-subtitle>{{ group.id }}</mat-card-subtitle>
            </div>
            <div class="field-action-buttons">
              @if (
                group.parent &&
                group.parent.type &&
                (inputTypeConfigurations[group.parent.type]?.canMoveChildren ?? true)
                ) {
                <button
                  mat-icon-button
                  type="button"
                  class="mat-link hide-on-drag"
                  [disabled]="getAvailableGroupsForMove(group).length === 0"
                  attr.data-testid="formlyControlEditor_{{ group.id }}_VerschiebenMenuButton"
                  (click)="openElementVerschiebenModal(group, 0); $event.stopImmediatePropagation()"
            attr.aria-label="{{
              'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Verschieben.Buttons.VerschiebenModal'
                | translate
            }}"
                  >
                  <mat-icon fontSet="fa" fontIcon="fa-copy"></mat-icon>
                </button>
              }
              <button
                mat-icon-button
                (click)="onClickModifyControl(group)"
                class="mat-link hide-on-drag"
                id="formlyControlEditor_{{ group.id }}_BearbeitenButton"
                attr.data-testid="formlyControlEditor_{{ group.id }}_BearbeitenButton"
                attr.aria-label="{{ 'Texte.Allgemein.Buttons.Bearbeiten' | translate }}"
                >
                <mat-icon fontSet="fa" fontIcon="fa-pencil"></mat-icon>
              </button>
              @if (
                group.parent &&
                group.parent.type &&
                (inputTypeConfigurations[group.parent.type]?.canDeleteChildren ?? true)
                ) {
                <button
                  mat-icon-button
                  class="mat-link hide-on-drag"
                  (click)="onClickDeleteControl(group)"
                  attr.data-testid="formlyControlEditor_{{ group.id }}_EntfernenButton"
                  attr.aria-label="{{ 'Texte.Allgemein.Buttons.Entfernen' | translate }}"
                  >
                  <mat-icon fontSet="fa" fontIcon="fa-trash"></mat-icon>
                </button>
              }
            </div>
          </div>
        }
        <!-- Gruppeninhalt ein-/ausklappen -->
        @if (group.parent) {
          <button
            type="button"
            class="expand-button"
            (click)="toggleGruppenInhalt(group)"
        attr.aria-label="{{
          'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Bearbeiten.Buttons.GruppenInhaltAusklappen'
            | translate
        }}"
            attr.data-testid="formlyControlEditor_{{ group.id }}_InhaltAusklappenButton"
            >
            <i
              class="fa"
          [ngClass]="{
            'fa-angle-down': !expandedState[group.id],
            'fa-angle-up': expandedState[group.id]
          }"
              aria-hidden="true"
            ></i>
          </button>
        }
        <!-- Gruppen Inhalt -->
        @if (!group.parent || expandedState[group.id]) {
          <div
            [class.group-content]="group.parent"
            class="drag-boundary"
            >
            <!-- Felder in der Gruppe -->
            @for (f of group.fieldGroup; track trackFormlyFieldConfigBy(i, f); let i = $index) {
              <mat-card
                cdkDrag
                cdkDragLockAxis="y"
                cdkDragBoundary=".drag-boundary"
                [ngClass]="{ 'drag-drop-animation-active': (accessibilityEnabled$ | async) === false }"
                [@.disabled]="accessibilityEnabled$ | async"
                attr.data-testid="formlyControlEditor_{{ f.id }}_Card"
          attr.aria-label="{{
            'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Bearbeiten.FormlyElementAriaLabel'
              | translate : { id: f.id, type: f.type }
          }}"
                (keyup.alt.arrowup)="moveControlUp($event, group, i)"
                (keyup.alt.arrowdown)="moveControlDown($event, group, i)"
                class="card full-width mat-elevation-z2"
                tabindex="0"
                id="formly-control-{{ group.id }}-{{ i }}"
                (click)="highlightActiveField(f, $event)"
                >
                <mat-card-header class="card-header">
                  <mat-card-title class="card-title">{{ f.type }}</mat-card-title>
                  <mat-card-subtitle class="card-subtitle">{{ f.id }}</mat-card-subtitle>
                  <!-- Aktionen für Felder -->
                  <div class="field-action-buttons">
                    @if (group.type && (inputTypeConfigurations[group.type]?.canMoveChildren ?? true)) {
                      <button
                        mat-icon-button
                        class="mat-link hide-on-drag"
                        (click)="moveControlUp($event, group, i)"
                        [disabled]="i === 0"
                attr.aria-label="{{
                  'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Verschieben.Buttons.HochSchieben'
                    | translate
                }}"
                        attr.data-testid="formlyControlEditor_{{ f.id }}_HochSchiebenButton"
                        >
                        <mat-icon fontSet="fa" fontIcon="fa-arrow-up"></mat-icon>
                      </button>
                    }
                    @if (group.type && (inputTypeConfigurations[group.type]?.canMoveChildren ?? true)) {
                      <button
                        mat-icon-button
                        class="mat-link hide-on-drag"
                        (click)="moveControlDown($event, group, i)"
                        [disabled]="!(i < group.fieldGroup.length - 1)"
                attr.aria-label="{{
                  'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Verschieben.Buttons.RunterSchieben'
                    | translate
                }}"
                        attr.data-testid="formlyControlEditor_{{ f.id }}_RunterSchiebenButton"
                        >
                        <mat-icon fontSet="fa" fontIcon="fa-arrow-down"></mat-icon>
                      </button>
                    }
                    @if (group.type && (inputTypeConfigurations[group.type]?.canMoveChildren ?? true)) {
                      <button
                        mat-icon-button
                        type="button"
                        class="mat-link hide-on-drag"
                        [disabled]="getAvailableGroupsForMove(f).length === 0"
                        (click)="openElementVerschiebenModal(f, i); $event.stopImmediatePropagation()"
                attr.aria-label="{{
                  'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Verschieben.Buttons.VerschiebenModal'
                    | translate
                }}"
                        attr.data-testid="formlyControlEditor_{{ f.id }}_VerschiebenMenuButton"
                        >
                        <mat-icon fontSet="fa" fontIcon="fa-copy"></mat-icon>
                      </button>
                    }
                    <button
                      mat-icon-button
                      (click)="onClickModifyControl(f)"
                      class="mat-link hide-on-drag"
                      id="formlyControlEditor_{{ f.id }}_BearbeitenButton"
                      attr.data-testid="formlyControlEditor_{{ f.id }}_BearbeitenButton"
                      attr.aria-label="{{ 'Texte.Allgemein.Buttons.Bearbeiten' | translate }}"
                      >
                      <mat-icon fontSet="fa" fontIcon="fa-pencil"></mat-icon>
                    </button>
                    @if (
                      group &&
                      group.type &&
                      (inputTypeConfigurations[group.type]?.canDeleteChildren ?? true)
                      ) {
                      <button
                        mat-icon-button
                        class="mat-link hide-on-drag"
                        (click)="onClickDeleteControl(f)"
                        attr.data-testid="formlyControlEditor_{{ f.id }}_EntfernenButton"
                        attr.aria-label="{{ 'Texte.Allgemein.Buttons.Entfernen' | translate }}"
                        >
                        <mat-icon fontSet="fa" fontIcon="fa-trash"></mat-icon>
                      </button>
                    }
                  </div>
                </mat-card-header>
                <mat-card-content>
                  <!-- Vorschau des Feldes -->
                  <formly-field [field]="f"></formly-field>
                </mat-card-content>
              </mat-card>
            }
            <!-- Buttons zum Hinzufügen von neuen Feldern in Gruppe -->
            <div>
              @if (group.fieldGroup.length === 0) {
                <div>
                  <p>
                    {{
                    'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Hinzufuegen.NochKeineElemente'
                    | translate
                    }}
                  </p>
                  <button
                    mat-button
                    class="mat-link"
                    (click)="onClickAddControl(group)"
              attr.data-testid="formlyControlEditor_{{
                group.id
              }}_Gruppe_NeuesElementHinzufügenButton"
                    id="formlyControlEditor_{{ group.id }}_Gruppe_NeuesElementHinzufügenButton"
                    >
                    <mat-icon fontSet="fa" fontIcon="fa-plus"></mat-icon>
                    {{
                    'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Hinzufuegen.Buttons.NeuesElementHinzufuegen'
                    | translate
                    }}
                  </button>
                </div>
              }
              @if (group.fieldGroup.length !== 0) {
                <div>
                  <button
                    mat-button
                    class="mat-link"
                    (click)="onClickAddControl(group)"
              attr.data-testid="formlyControlEditor_{{
                group.id
              }}_Gruppe_WeiteresElementHinzufügenButton"
                    >
                    <mat-icon fontSet="fa" fontIcon="fa-plus"></mat-icon>
                    {{
                    'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Hinzufuegen.Buttons.WeiteresElementHinzufuegen'
                    | translate
                    }}
                  </button>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
  </ng-template>
</formly-form>
