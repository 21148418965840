import { AsyncPipe, DOCUMENT, NgClass } from '@angular/common';

import { CdkDrag, CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, FormRecord } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AccessibilityService } from '@dworkflow/shared/services/accessibility.service';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { defaultType, types } from '@dworkflow-formly-editor/shared/configurations/formEditorTypes';
import { FormEditorType } from '@dworkflow-formly-editor/shared/model/form-editor-type.model';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import lodash from 'lodash';
import { Observable } from 'rxjs';
import { FormulareHelper } from '../shared/utility/formulare-helper';

@Component({
  selector: 'dworkflow-formly-editor-internal',
  templateUrl: './formly-editor-internal.component.html',
  styleUrls: ['./formly-editor-internal.component.scss'],
  standalone: true,
  imports: [
    FormlyModule,
    CdkDropList,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    NgClass,
    CdkDrag,
    AsyncPipe,
    TranslateModule
],
})
export class FormlyEditorInternalComponent implements OnInit, OnChanges {
  @Input() fields: FormlyFieldConfig[] = [];
  @Output() deleteControl = new EventEmitter<string>();
  @Output() moveControl = new EventEmitter<{
    prevGroupId: string;
    currGroupId: string | undefined;
    prevIndex: number;
    currIndex: number | undefined;
    copy: boolean | undefined;
  }>();
  @Output() addControl = new EventEmitter<{
    idGroup: string;
    newField: FormlyFieldConfig;
  }>();
  @Output() modifyControl = new EventEmitter<{
    idGroup: string;
    index: number;
    modifiedField: FormlyFieldConfig;
  }>();

  expandedState: { [id: string]: boolean } = {};

  formlyForm = new FormGroup({});
  formlyModel: { [key: string]: unknown } = {};
  formlyOptions: FormlyFormOptions = {
    formState: {
      awesomeIsForced: false,
    },
  };
  formlyFields: FormlyFieldConfig[] = [];

  accessibilityEnabled$: Observable<boolean> =
    this.accessibilityService.isAccessibilityModusEnabled();

  inputTypeConfigurations: { [key: string]: FormEditorType } = {};

  readonly formlyGroupType = FormulareHelper.formlyGroupType;

  constructor(
    private dialog: MatDialog,
    private cdref: ChangeDetectorRef,
    private translate: TranslateService,
    private accessibilityService: AccessibilityService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fields) {
      this.formlyFields = this.initEditorPreviewFields(lodash.cloneDeep(this.fields));
      /*
        Durch das Hinzufügen einer Zeile für jedes Repeat-Feld ändert sich das Model.
        Um einen dadurch entstehenden ExpressionChangedAfterChecked Fehler zu vermeiden,
        wird detectChanges verwendet.
      */
      this.cdref.detectChanges();
    }
  }

  ngOnInit(): void {
    this.inputTypeConfigurations = types; // gebraucht damit vom template aus auf types zugegriffen werden kann
  }

  onClickDeleteControl(f: FormlyFieldConfig): void {
    this.deleteControl.emit(f.id);
    delete this.expandedState[f.id];

    setTimeout(() => {
      const parent = f.parent;
      const parentID = parent.id;
      const count = f.parent?.fieldGroup?.length ?? 0;
      const index = this.getIndexInParent(f);

      if (count === 0) {
        this.document
          .getElementById(`formlyControlEditor_${parentID}_Gruppe_NeuesElementHinzufügenButton`)
          ?.focus();
      } else {
        const focusIndex = index - 1 >= 0 ? index - 1 : 0;
        this.document
          .getElementById(
            `formly-control-${parentID ?? FormulareHelper.formlyBaseId}-${focusIndex}`
          )
          ?.focus();
      }
    });
  }

  onClickAddControl(f: FormlyFieldConfig): void {
    if (f.id === undefined || f.fieldGroup === undefined) {
      return;
    }
    import('./feld-bearbeiten/feld-bearbeiten-modal/feld-bearbeiten-modal.component').then(c => {
      const dialogRef = this.dialog.open(c.FeldBearbeitenModalComponent, {
        panelClass: 'modal-large-size',
        disableClose: false,
        ariaLabel: this.translate.instant(
          'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Hinzufuegen.Titel'
        ) as string,
        data: {
          speichernButtonText: this.translate.instant(
            'Texte.Allgemein.Buttons.Hinzufuegen'
          ) as string,
          titelText: this.translate.instant(
            'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Hinzufuegen.Titel'
          ) as string,
          form: new FormRecord({ type: new FormControl(defaultType.typeName) }),
        },
      });

      dialogRef.afterClosed().subscribe((data: FormRecord) => {
        if (data) {
          const groupId = f.id;
          this.addControl.emit({
            idGroup: groupId,
            newField: FormulareHelper.convertFormToConfig(data),
          });
          setTimeout(() => {
            this.document
              .getElementById(
                `formly-control-${groupId ?? FormulareHelper.formlyBaseId}-${
                  f.fieldGroup.length - 1
                }`
              )
              ?.focus();
          });
        }
      });
    });
  }

  onClickModifyControl(f: FormlyFieldConfig): void {
    const element = FormulareHelper.findFieldById(f.id, this.fields);
    // es wird das "originale" Feld bearbeitet, nicht das welches in formly eingebunden wurde,
    // da formly viele weitere Attribute hinzufügt, die für die NutzerIn verwirrend sein könnten
    if (!element) {
      return;
    }
    const group = f.parent.id;
    const index = this.getIndexInParent(f);
    import('./feld-bearbeiten/feld-bearbeiten-modal/feld-bearbeiten-modal.component').then(c => {
      const dialogRef = this.dialog.open(c.FeldBearbeitenModalComponent, {
        panelClass: 'modal-large-size',
        disableClose: false,
        data: {
          form: FormulareHelper.convertFormlyFieldConfigToFormRecord(element),
          speichernButtonText: this.translate.instant(
            'Texte.Allgemein.Buttons.Speichern'
          ) as string,
          titelText: this.translate.instant(
            'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Bearbeiten.Titel'
          ) as string,
        },
        ariaLabel: this.translate.instant(
          'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Bearbeiten.Titel'
        ) as string,
      });

      dialogRef.afterClosed().subscribe((data: FormRecord) => {
        if (data) {
          const modifiedField = FormulareHelper.convertFormToConfig(data);
          this.modifyControl.emit({
            idGroup: group,
            index: index,
            modifiedField: modifiedField,
          });
          setTimeout(() => {
            this.document
              .getElementById(`formlyControlEditor_${modifiedField.id}_BearbeitenButton`)
              ?.focus();
          }, 0);
        }
      });
    });
  }

  getIndexInParent(field: FormlyFieldConfig): number {
    let result = 0;
    field.parent?.fieldGroup?.forEach((f, i) => {
      if (field.id === f.id) {
        result = i;
      }
    });
    return result;
  }

  getType(f: FormlyFieldConfig): string {
    return f.type as string;
  }

  openElementVerschiebenModal(f: FormlyFieldConfig, i: number): void {
    import('./feld-kopieren-modal/feld-kopieren-modal.component').then(c => {
      const modal = this.dialog.open(c.FeldKopierenModalComponent, {
        data: {
          gruppen: this.getAvailableGroupsForMove(f),
          element: f,
        },
        disableClose: true,
        ariaLabel: this.translate.instant(
          'Texte.Grundeinstellungen.Formulare.Editor.Aktionen.Verschieben.Modal.Titel',
          { elementId: f.id }
        ) as string,
        panelClass: 'modal-small-size',
      });
      modal.afterClosed().subscribe((val: { groupId: string; verschieben: boolean }) => {
        if (!val) {
          return;
        }
        const data: {
          prevGroupId: string;
          currGroupId: string | undefined;
          prevIndex: number;
          currIndex: number | undefined;
          copy: boolean | undefined;
        } = {
          prevGroupId: f.parent.id,
          currGroupId: val.groupId,
          prevIndex: i,
          currIndex: undefined,
          copy: !val.verschieben,
        };
        this.onMoveControl(data, f.id);
      });
    });
  }

  drop(event: CdkDragDrop<FormlyFieldConfig[]>, gruppe: FormlyFieldConfig): void {
    if (event.currentIndex !== event.previousIndex) {
      const id = gruppe.fieldGroup[event.previousIndex].id;
      const groupId = gruppe.id;
      const data: {
        prevGroupId: string;
        currGroupId: string | undefined;
        prevIndex: number;
        currIndex: number | undefined;
        copy: boolean | undefined;
      } = {
        prevGroupId: groupId,
        currGroupId: groupId,
        prevIndex: event.previousIndex,
        currIndex: event.currentIndex,
        copy: undefined,
      };
      this.onMoveControl(data, id);
    }
  }

  onMoveControl(
    data: {
      prevGroupId: string;
      currGroupId: string | undefined;
      prevIndex: number;
      currIndex: number | undefined;
      copy: boolean | undefined;
    },
    id: string
  ): void {
    this.moveControl.emit(data);
    this.setPanelExpandedState(id, false);

    setTimeout(() => {
      this.document
        .getElementById(
          `formly-control-${data.currGroupId ?? FormulareHelper.formlyBaseId}-${data.currIndex}`
        )
        ?.focus();
    });
  }

  moveControlUp(event: Event, gruppe: FormlyFieldConfig, index: number): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (index !== 0) {
      const gruppeId = gruppe.id;
      const id = gruppe.fieldGroup[index].id;
      const data: {
        prevGroupId: string;
        currGroupId: string | undefined;
        prevIndex: number;
        currIndex: number | undefined;
        copy: boolean | undefined;
      } = {
        prevGroupId: gruppeId,
        currGroupId: gruppeId,
        prevIndex: index,
        currIndex: index - 1,
        copy: undefined,
      };
      this.onMoveControl(data, id);
    }
  }

  moveControlDown(event: Event, gruppe: FormlyFieldConfig, index: number): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    if (index < gruppe.fieldGroup.length) {
      const gruppeId = gruppe.id;
      const id = gruppe.fieldGroup[index].id;
      const data: {
        prevGroupId: string;
        currGroupId: string | undefined;
        prevIndex: number;
        currIndex: number | undefined;
        copy: boolean | undefined;
      } = {
        prevGroupId: gruppeId,
        currGroupId: gruppeId,
        prevIndex: index,
        currIndex: index + 1,
        copy: undefined,
      };
      this.onMoveControl(data, id);
    }
  }

  initEditorPreviewFields(fields: FormlyFieldConfig[]): FormlyFieldConfig[] {
    fields.forEach(field => {
      this.initRepeatField(field); // add one row to every repeat and disable adding/removing rows
      this.removeHideExpressions(field); // remove hide expression from field

      // Rekursiver Aufruf für Inhalt der Fields
      if (field.fieldArray) {
        this.initEditorPreviewFields([field.fieldArray as FormlyFieldConfig]);
      }

      if (field.fieldGroup) {
        this.initEditorPreviewFields(field.fieldGroup);
      }
    });
    return fields;
  }

  initRepeatField(field: FormlyFieldConfig): FormlyFieldConfig {
    if (field.fieldArray) {
      field.props = {
        ...field.props,
        minRows: 1,
        maxRows: 1,
      };
    }
    return field;
  }

  removeHideExpressions(field: FormlyFieldConfig): FormlyFieldConfig {
    if (field.expressions?.hide) {
      delete field.expressions.hide;
    }
    return field;
  }

  setPanelExpandedState(id: string, state: boolean): void {
    this.expandedState[id] = state;
  }

  getAvailableGroupsForMove(f: FormlyFieldConfig): FormlyFieldConfig[] {
    const gruppeList: FormlyFieldConfig[] = [
      { id: FormulareHelper.formlyBaseId, fieldGroup: this.formlyFields },
    ];

    const getGruppen = (fields: FormlyFieldConfig[]): void => {
      fields.map(item => {
        if (!item.id || item.id === f.id) {
          return;
        }
        if (item.fieldArray) {
          getGruppen([item.fieldArray as FormlyFieldConfig]);
          return;
        }
        if (item.fieldGroup) {
          if (this.inputTypeConfigurations[item.type as string]?.canMoveChildren ?? true) {
            gruppeList.push(item);
          }
          getGruppen(item.fieldGroup);
        }
      });
    };

    getGruppen(this.formlyFields);
    return gruppeList;
  }

  trackFormlyFieldConfigBy(_: number, field: FormlyFieldConfig): string {
    return field.id;
  }

  toggleGruppenInhalt(gruppe: FormlyFieldConfig): void {
    this.expandedState[gruppe.id] = !this.expandedState[gruppe.id];
    /*
      Beim Ausklappen der Inhalte entsteht Formly intern (FormlyFieldWrapperComponent) bei komplexen Formularen
      ein ExpressioChangedAfterChecked Fehler. Dieser wird mit detectChanges vermieden
    */
    this.cdref.detectChanges();
  }

  highlightActiveField(f: FormlyFieldConfig, event: Event): void {
    this.removeActiveFieldHighlight();

    const newFocusElement = this.document.getElementById(
      f?.id + FormulareHelper.formlyVorschauIdPostfix
    );

    if (newFocusElement) {
      event.stopPropagation();
      newFocusElement.closest('formly-field')?.classList.add('active-editor-field');
    }
  }

  removeActiveFieldHighlight(): void {
    Array.from(this.document.getElementsByClassName('active-editor-field')).forEach(element =>
      element.classList.remove('active-editor-field')
    );
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: PointerEvent): void {
    const clickedInside: boolean = (event.target as HTMLElement).closest('mat-card') !== null;
    if (!clickedInside) {
      this.removeActiveFieldHighlight();
    }
  }
}
