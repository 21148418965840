import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import lodash from 'lodash';
import { FormlyEditorInternalComponent } from '../formly-editor-internal/formly-editor-internal.component';
import { types } from '../shared/configurations/formEditorTypes';
import { FormulareHelper } from '../shared/utility/formulare-helper';

@Component({
  selector: 'dworkflow-formly-editor',
  templateUrl: './formly-editor.component.html',
  styleUrls: ['./formly-editor.component.scss'],
  standalone: true,
  imports: [FormlyEditorInternalComponent],
})
export class FormlyEditorComponent implements OnChanges {
  @Input() fields: FormlyFieldConfig[];
  @Output() changeFields: EventEmitter<FormlyFieldConfig[]> = new EventEmitter<
    FormlyFieldConfig[]
  >();

  idCounter = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fields) {
      const fields = this.fields;
      this.idCounter = this.getMaxIdCounter(fields); // reset id counter
      this.checkAndFormatFields(fields);
      this.changeFields.emit(fields);
      this.fields = fields;
    }
  }

  onAddControl(data: { idGroup: string; newField: FormlyFieldConfig }): void {
    this.checkAndFormatField(data.newField);
    const group: FormlyFieldConfig[] =
      FormulareHelper.findFieldById(data.idGroup, this.fields)?.fieldGroup ?? this.fields;
    group.push(data.newField);
    this.updateFields(this.fields);
  }

  onDeleteControl(id: string): void {
    const fields = this.fields.filter(function f(field): boolean {
      if (field.id === id) {
        return false;
      }
      if (field.fieldGroup) {
        field.fieldGroup = field.fieldGroup.filter(f);
      }
      if (field.fieldArray) {
        field.fieldArray = [field.fieldArray as FormlyFieldConfig].filter(f)[0];
      }
      return true;
    });
    this.checkAndFormatFields(fields);
    this.updateFields(fields);
  }

  onMoveControl(data: {
    prevGroupId: string;
    currGroupId: string | undefined;
    prevIndex: number;
    currIndex: number | undefined;
    copy: boolean | undefined;
  }): void {
    const prevGroup: FormlyFieldConfig[] | undefined =
      FormulareHelper.findFieldById(data.prevGroupId, this.fields)?.fieldGroup ?? this.fields;
    if (
      data.currIndex !== undefined &&
      (!data.currGroupId || data.currGroupId === data.prevGroupId)
    ) {
      moveItemInArray(prevGroup, data.prevIndex, data.currIndex);
      this.updateFields(this.fields);
    } else if (data.currGroupId) {
      const currGroup: FormlyFieldConfig[] | undefined =
        FormulareHelper.findFieldById(data.currGroupId, this.fields)?.fieldGroup ?? this.fields;
      if (!data.copy) {
        transferArrayItem(prevGroup, currGroup, data.prevIndex, currGroup.length);
      } else {
        const copy: FormlyFieldConfig = lodash.cloneDeep(prevGroup[data.prevIndex]);
        this.addCopyPostfix(copy);
        this.checkAndFormatField(copy);
        currGroup.push(copy);
      }
      this.updateFields(this.fields);
    }
  }

  onModifyControl(data: {
    idGroup: string;
    index: number;
    modifiedField: FormlyFieldConfig;
  }): void {
    const parent: FormlyFieldConfig | undefined = FormulareHelper.findFieldById(
      data.idGroup,
      this.fields
    );
    const group: FormlyFieldConfig[] | undefined = parent?.fieldGroup;
    if (!group && parent?.fieldArray) {
      parent.fieldArray = data.modifiedField;
    } else {
      (group ?? this.fields)[data.index] = data.modifiedField;
    }
    if (parent) {
      this.checkAndFormatField(parent);
    } else {
      this.checkAndFormatField(data.modifiedField);
    }

    this.updateFields(this.fields);
  }

  onModifyFields(newFields: FormlyFieldConfig[]): void {
    this.checkAndFormatFields(newFields);
    this.updateFields(newFields);
  }

  updateFields(fields: FormlyFieldConfig[]): void {
    this.fields = lodash.cloneDeep(fields); // benötigt damit change detection veränderte fields mitbekommt (sonst kein update da gleiches objekt)
    this.changeFields.emit(this.fields);
  }

  addCopyPostfix(field: FormlyFieldConfig): void {
    field.id = field.id + '_copy';
    if (field.key) {
      field.key = (field.key as string) + '_copy';
    }
    if (field.fieldArray) {
      this.addCopyPostfix(field.fieldArray as FormlyFieldConfig);
    }
    if (field.fieldGroup) {
      field.fieldGroup.forEach(field => {
        this.addCopyPostfix(field);
      });
    }
  }

  checkAndFormatFields(fields: FormlyFieldConfig[]): void {
    fields.forEach(field => this.checkAndFormatField(field));
  }

  checkAndFormatField(field: FormlyFieldConfig): void {
    // fehlende Daten füllen
    this.checkMissingType(field);
    this.checkMissingId(field);

    // besondere input typen prüfen
    const typeConfig = types[field.type as string];
    if (typeConfig && typeConfig.onChangeField) {
      typeConfig.onChangeField(field);
    }

    // Kinder des Feldes cleanen
    if (field.fieldArray) {
      this.checkAndFormatField(field.fieldArray as FormlyFieldConfig);
    }
    if (field.fieldGroup) {
      this.checkAndFormatFields(field.fieldGroup);
    }
  }

  checkMissingType(field: FormlyFieldConfig): void {
    const type: string | undefined = field.type as string;

    if (type === undefined || type === '') {
      field.type = FormulareHelper.formlyGroupType;
    }
  }

  checkMissingId(field: FormlyFieldConfig): void {
    const type: string | undefined = field.type as string;
    const id: string | undefined = field.id;
    if (!id) {
      field.id =
        'formly_' +
        (type === undefined || type === FormulareHelper.formlyGroupType ? 'group' : type) +
        '_' +
        String(++this.idCounter);
    }
  }

  getMaxIdCounter(fields: FormlyFieldConfig[]): number {
    let max = 0;
    fields.forEach(field => {
      const id = field.id?.split('_') ?? [];
      if (id.length !== 0) {
        const idNumber = Number(id[id.length - 1]);
        if (!isNaN(idNumber) && idNumber > max) {
          max = idNumber;
        }
      }

      if (field.fieldGroup) {
        const fieldGroupMax = this.getMaxIdCounter(field.fieldGroup);
        if (fieldGroupMax > max) {
          max = fieldGroupMax;
        }
      }

      if (field.fieldArray) {
        const fieldArrayMax = this.getMaxIdCounter([field.fieldArray as FormlyFieldConfig]);
        if (fieldArrayMax > max) {
          max = fieldArrayMax;
        }
      }
    });
    return max;
  }
}
